import React from 'react'
import { Helmet } from "react-helmet";

const JsonLD = ({ json }) => {
    return (
        <Helmet >
            <script type='application/ld+json'>{JSON.stringify(json, null, 2)}</script>
        </Helmet>
    )
}

export default JsonLD