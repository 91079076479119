import React, { useState } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import styles from "./styles";
import HeaderSection from "../HeaderSection";
import { withStyles, useTheme } from "@material-ui/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { StaticQuery, graphql, Link } from "gatsby";


const Pricing = ({ data, classes, location }) => {
  const showButton = location.pathname === "/en/courses" ? true : false;
  const { pricing } = data.allMarkdownRemark.edges[0].node.frontmatter;
  const [selected, setSelected] = useState(1);
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const handleSelectPayment = (i) => {
    if (selected === i) setSelected(undefined);
    else setSelected(i);
  };
  return (
    <>
      <HeaderSection
        header={pricing.heading}
        subheader={pricing.description}
        location={location}
      />
      <Grid container
        justifyContent="center"
        alignItems="center"
        spacing={5}>
        {pricing.plans.map((plan, i) => (
          <Grid item
            className={classes.gridItem}
            key={plan.plan} xs={12} md={6}>
            <Card className={classes.grid}>
              <CardHeader
                title={plan.plan}
                subheader={plan.description}
                titleTypographyProps={{ align: "center" }}
                subheaderTypographyProps={{
                  align: "center",
                  color: "inherit",
                }}
                style={{
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.common.white,
                }

                }
              />
              <CardContent>
                <div className={classes.cardPricing}>
                  {plan.description === "installments" ? (
                    <Typography
                      component="p"
                      variant="body1"
                      color="textPrimary"
                    // style={{ paddingRight: theme.spacing(0) }}
                    >
                      {plan.price}
                    </Typography>
                  ) : (
                    <React.Fragment>
                      {plan.from && (
                        <Typography
                          component="p"
                          variant="body1"
                          color="textPrimary"
                          style={{ paddingRight: theme.spacing(0) }}
                        >
                          from:&nbsp;
                        </Typography>
                      )}

                      <Typography
                        component="h2"
                        variant="h3"
                        color="textPrimary"
                      >
                        {plan.price}
                      </Typography>
                    </React.Fragment>
                  )}
                </div>
                <ul>
                  {plan.items.map((line) => (
                    <Typography
                      component="li"
                      variant="body1"
                      align="center"
                      key={line}
                    >
                      {line}
                    </Typography>
                  ))}
                </ul>
              </CardContent>
              {plan.link && <CardActions className={classes.cardActions}>
                <Button
                  component={Link}
                  to={`/${plan.link}`}

                  onClick={() => handleSelectPayment(i)}
                  variant={"outlined"}
                  color="primary"
                >

                  Learn more

                </Button>
              </CardActions>}
              {plan.extra &&
                <CardActions>
                  <Typography
                    component="p"
                    variant="body1"
                    color="textPrimary"
                    style={{ paddingRight: theme.spacing(0) }}
                  >
                    {plan.extra}
                  </Typography>
                </CardActions>}

            </Card>

          </Grid>
        ))}
      </Grid>

    </>
  );
};

Pricing.propTypes = {
  data: PropTypes.shape({}),
  classes: PropTypes.shape({}),
};
withStyles(styles)(Pricing);

const PricingQuery = ({ location, classes }) => (
  <StaticQuery
    query={graphql`
      query PricingQuery {
        allMarkdownRemark(
          filter: { frontmatter: { key: { eq: "pricing" } } }
        ) {
          edges {
            node {
              frontmatter {
                pricing {
                  heading
                  description
                  plans {
                    description
                    items
                    plan
                    price
                    link
                    extra
                    from
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <Pricing data={data} location={location} classes={classes} />
    )}
  />
);
export default withStyles(styles)(PricingQuery);
