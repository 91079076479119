const styles = (theme) => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },
  more: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
  },
  cardActions: {
    justifyContent: "center",
  },
  container: {
    width: "100%",
    overflow: "hidden",
    paddingBottom: theme.spacing(1),
  },
  gridItem: {

    display: "flex",
    justifyContent: "center",
  },
  grid: {
    flex: 1,
    maxWidth: 600,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
    },
  },
});
export default styles;
