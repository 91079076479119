import { Height } from "@material-ui/icons";

const styles = (theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 400,
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.text.primary,
  },
  cardActions: {
    display: "flex",
    justifyContent: "center",
  },
  container: {
    marginBottom: theme.spacing(4),
    maxWidth: "100vw",

    overflow: "hidden",
    alignItems: "baseline",
    padding: 0,
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingBottom: theme.spacing(1),
    },
  },

  link: {
    textDecoration: "none",
  },
  description: {
    "& p": {
      fontSize: "1rem",
      textAlign: "center",
    },
  },
  avatar: {
    width: 30,
    height: 30
  },
});
export default styles;
